<template>
  <page :data="pageData" :is-child="_isChild" @refresh="refresh">
    <template #aftercmsleftcomponents>
      <div class="p-2">
        <UploadSlipButton @done="done" :id="id" />
      </div>
    </template>
  </page>
</template>

<script>
import CMSPage from './base/CMSPage'
export default {
  extends: CMSPage,
  components: {
    UploadSlipButton: () => import('@/components/vaddress/UploadSlipButton')
  },
  methods: {
    done () {
       this.$router.replace('/user/orders')
    }
  },
  computed: {
    id () {
      return this.$route.query.Oid
    }
  }
}
</script>

<style>

</style>
